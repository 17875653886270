import actions from './actions';
import mutations from './mutations';

export default {
    namespaced: true,
    state() {
        return {
            allSymptoms: [],
            selectedSymptomIds: [],
            matchedDisease: [],
            selectedDisease: {},
            selectedSymptoms: []
        }
    },
    actions,
    mutations,
}