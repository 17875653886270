import {fetchData} from "@/helpers/apiRuquests";

export default {
    getAll({commit}) {
        fetchData('appointments', response => {
            commit('SET_APPOINTMENT_DATA', response.data);
        });
    },
    update(context, params) {
        console.log('function called')
        console.log(params)
        // postData(
        //     params.url,
        //     params.data,
        //     response => {
        //         console.log(response)
        //     }
        // );
    }
};