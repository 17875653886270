import {fetchData, postData} from "@/helpers/apiRuquests";
import {empty, fireNotification} from "@/helpers/utility";

export default {
    async update(context, params) {
        let response = await postData(params.url, params.data);
        if (params?.showAlert) {
            fireNotification({
                text: response.data.Message,
                color: 'success',
                action: true
            });
        }
        return response;
    },
    async store(context, params) {
        return await postData('appointments', params);
    },
    async search(context, params) {
        return await postData('search', params);
    },
    async get(context, params = null) {
        let response = await fetchData('appointments', params);
        context.commit('SET_APPOINTMENT_LIST', response.data);
        return response;
    },
    async details(context, id) {
        let response = await fetchData(`appointments/${parseInt(id)}`);
        context.commit('SET_PHYSICAL_INVESTIGATION', response.data.physicalInvestigations);
        return response;
    },
    async getPhysicalInvestigationFormData(context) {
        let params = {};
        let investigationData = context.state.physicalInvestigation;

        if (!empty(investigationData.height.ft) && !empty(investigationData.height.in)) {
            params.height = investigationData.height;
        }
        if (!empty(investigationData.bp.dia) && !empty(investigationData.bp.sys)) {
            params.bp = investigationData.bp;
        }

        if (!empty(investigationData.heartRate.value)) {
            params.heartRate = investigationData.heartRate;
        }

        if (!empty(investigationData.bloodOxygen.value)) {
            params.bloodOxygen = investigationData.bloodOxygen;
        }

        if (!empty(investigationData.temperature.value)) {
            params.temperature = investigationData.temperature;
        }

        if (!empty(investigationData.weight.value)) {
            params.weight = investigationData.weight;
        }
        return params;
    },
    async storeReports(context, params) {
        return await postData('/investigations/reports', params);
    }
};