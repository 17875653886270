import VueCookie from 'vue-cookies';
import router from "@/router";
import {postData, fetchData} from "@/helpers/apiRuquests";

let tokenRefreshing = false;
const state = {
    auth: VueCookie.isKey('auth') ? VueCookie.get('auth') : null,
    profile: '',
    loggedInUserName: VueCookie.isKey('userName') ? VueCookie.get('userName') : null,
};
const actions = {
    insertAuthData({commit}, payload) {
        commit('SET_AUTH_DATA', payload);
    },
    getProfile({commit}) {
        fetchData('profile', response => {
            commit('SET_USER_DATA', response.data);
        });
    },
    refreshToken({dispatch, commit}, {auth}) {
        if (auth) {
            if (!tokenRefreshing) {
                tokenRefreshing = true;
                refreshToken(dispatch, commit);
            }
        }
    },
    logout({commit}) {
        commit('SET_AUTH_DATA', null);
        // if (router.currentRoute.name !== 'Home') {
            router.push({
                name: 'Login',
                // query: {redirectTo: router.currentRoute.fullPath}
            });
        // }
    },
}
const mutations = {
    SET_USER_DATA: (state, payload) => {
        state.profile = payload;
        VueCookie.set('userName', state.profile.name);

    },
    SET_USER_DISPLAY_PICTURE: (state, payload) => {
        state.user.display_picture = payload;
        if (payload) {
            VueCookie.set('user', state.user, state.auth.access_token_expire_in)
        }
    },
    SET_AUTH_DATA: (state, payload) => {
        let authData = null;
        if (payload) {
            authData = Object.assign({}, payload);
            if (authData) {
                delete authData['auth'];
            }
            VueCookie.set('auth', authData, payload.access_token_expire_in);
        } else {
            VueCookie.remove('auth');
            VueCookie.remove('userName');
            VueCookie.remove('symptoms');
            VueCookie.remove('selectedInvestigations');
            VueCookie.remove('diseaseObj');
            VueCookie.remove('selectedTreatments');
            VueCookie.remove('selectedSymptoms');
            VueCookie.remove('matched_disease');
        }
        state.auth = authData;
    }
};
const getters = {
    authData: state => {
        return state.auth;
    },
    userName: state => {
        return state.loggedInUserName;
    }
};

function refreshToken(dispatch) {
    postData('auth', null, (response) => {
        console.log("refresh token", response)
        dispatch('insertAuthData', response.data.refresh_token);
        setTimeout(() => {
            tokenRefreshing ? tokenRefreshing = false : null;
        }, 5000);
    });
}

export default {
    state,
    mutations,
    getters,
    actions,
}