import actions from './actions';
import mutations from './mutations';

export default {
    namespaced: true,
    state() {
        return {
            allInvestigations: [],
            investigationsForDisease: [],
            selectedInvestigations: []
        }
    },
    actions,
    mutations,
}