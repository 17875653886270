import {fetchData, postData} from "@/helpers/apiRuquests";

export default {
    async list(context, params = null) {
        let response;
        if (params?.id) {
            response = await fetchData(`/treatments/${params.id}`);
            context.commit('SET_TREATMENTS_FOR_THE_DISEASE', response.data);
        } else {
            response = await fetchData('/treatments');
            context.commit('SET_ALL_TREATMENTS', response.data);
        }

        return response;
    },
    async store(context, params) {
        let response = await postData('/symptoms', params);
        let data = response.data;
        data.symptom_bn = null;
        data.status = 1;
        context.commit('SET_SELECTED_SYMPTOM_IDS', data.id);
        context.commit('SET_ONE_SYMPTOM', data);
        return data;
    },
    async diseasePrediction(context, params) {
        let response = await postData('/prediction', params);
        context.commit('SET_MATCHED_DISEASE', response.data);
        return response;
    }
}