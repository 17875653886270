export default {
    SET_ALL_SYMPTOMS(state, payload) {
        state.allSymptoms = payload;
    },
    SET_MATCHED_DISEASE(state, payload) {
        state.matchedDisease = payload;
    },
    SET_SELECTED_SYMPTOMS(state, payload) {
        state.selectedSymptoms = JSON.parse(payload);
    },
    SET_ONE_SYMPTOM(state, payload) {
        state.allSymptoms.push(payload);
    },
    SET_SELECTED_SYMPTOM_IDS(state, id) {
        let index = state.selectedSymptomIds.indexOf(id);
        if (index < 0) {
            state.selectedSymptomIds.push(id);
        }
    },
    REMOVE_SELECTED_SYMPTOM_IDS(state, id) {
        let index = state.selectedSymptomIds.indexOf(id);
        if (index >= 0) {
            state.selectedSymptomIds.splice(index, 1);
        }
    },
    SET_SELECTED_DISEASE(state, payload) {
        state.selectedDisease = payload;
    }
}