export default {
    SET_ALL_INVESTIGATIONS(state, payload) {
        state.allInvestigations = payload;
    },
    SET_INVESTIGATIONS_FOR_THE_DISEASE(state, payload) {
        state.investigationsForDisease = payload;
    },
    SET_SELECTED_INVESTIGATIONS(state, payload) {
        state.selectedInvestigations = payload;
    },
    SET_ONE_INVESTIGATION(state, payload) {
        state.investigationsForDisease.push(payload);
    },
    REMOVE_ONE_INVESTIGATION(state, key) {
        state.investigationsForDisease.splice(key, 1);
    }
}