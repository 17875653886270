import axios from 'axios';
import VueCookies from 'vue-cookies';

const $axios = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    contentType: 'application/json',
});


// withCredentials: true,
// headers: {'Access-Control-Allow-Origin': '*'}

const checkAuth = () => {
    if ((!VueCookies.isKey('auth') && $axios.defaults.headers.common['Authorization']) ||
        (`Bearer ${VueCookies.isKey('auth') ? VueCookies.get('auth').token : ''}` !== $axios.defaults.headers.common['Authorization'])
    ) {
        removeToken();
    }
    if (!$axios.defaults.headers.common['Authorization'] && VueCookies.isKey('auth')) {
        let authCookie = VueCookies.get('auth');
        if (authCookie && authCookie.token) {
            let token = authCookie.token;
            $axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }

}

const removeToken = () => {
    $axios.defaults.headers.common['Authorization'] = undefined;
}

const fetchData = async (url, params = null) => {
    checkAuth();
    if (params) {
        return await $axios.get(url, {params});
    }
    return await $axios.get(url);
}

const postData = async (url, data) => {
    checkAuth();
    if (data) {
        return await $axios.post(url, data);
    }
    return await $axios.post(url);
}

export {
    fetchData,
    postData,
}