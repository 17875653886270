import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import authStore from './modules/auth/index';
import appointments from './modules/appointment/index';
import prescriptionStore from './modules/prescription/index';
import profileStore from './modules/profile/index';
import caseHistoryStore from "@/store/modules/caseHistory";
import symptomsStore from "@/store/modules/symptoms";
import investigationStore from './modules/investigation/index';
import treatmentStore from "./modules/treatment/index";

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        auth,
        authStore,
        appointments,
        prescriptionStore,
        profileStore,
        caseHistoryStore,
        symptomsStore,
        investigationStore,
        treatmentStore,
    },
})