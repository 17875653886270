import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from "vue-cookies";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: "Login",
        component: () => import('@/view/Login'),
        meta: {
            isPublic: true
        }
    },
    {
        path: '/registration',
        name: "Registration",
        component: () => import('@/view/Registration'),
        meta: {
            isPublic: true
        }
    },
    {
        path: '/confirmed-appointment',
        name: "ConfirmAppointments",
        component: () => import('@/view/ConfirmAppointments'),
        meta: {
            isPublic: false
        }
    },
    {
        path: '/my-patients',
        name: "MyPatients",
        component: () => import('@/view/MyPatients'),
        meta: {
            isPublic: false
        }
    },
    {
        path: '/new-appointment',
        name: "NewAppointment",
        component: () => import('@/view/NewAppointment'),
        meta: {
            isPublic: false
        }
    },
    {
        path: '/edit-profile',
        name: "EditProfile",
        component: () => import('@/view/Profile'),
        meta: {
            isPublic: false
        }
    },
    {
        path: '/appointment/:appointmentId/patient/:id',
        name: "PatientCaseHistory",
        props: true,
        component: () => import('@/view/PatientCaseHistory'),
        meta: {
            isPublic: false
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: "not-found",
        component: () => import('@/components/404'),
        meta: {
            isPublic: false
        }
    }
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            const isHash = to.hash ? to.hash.substring(1) : undefined
            if (isHash) {
                let tmp = setTimeout(() => {
                    document.getElementById(isHash).scrollIntoView({
                        behavior: "smooth"
                    })
                    clearTimeout(tmp)
                })
            }
            return {
                x: 0,
                y: 0
            }
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => !record.meta.isPublic)) {
        // if route is not public
        if (VueCookies.isKey('auth')) {
            // if user is authenticated
            return next();
        }
        return next('/home');
    } else if (to.matched.some(record => record.meta.isPublic)) {
        // if route is public
        if (VueCookies.isKey('auth')) {
            // if user is authenticated
            return next('/confirmed-appointment');
        }
    }
    return next();
});

export default router;