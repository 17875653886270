import actions from "@/store/modules/prescription/actions";

export default {
    namespaced: true,
    state() {
        return {
            symptoms: [],
            physicalInvestigations: {
                height: {
                    ft: '',
                    in : ''
                },
                temperature: {
                    unit: "F",
                    value : ''
                },
                weight : {
                    unit: "kg",
                    value: ''
                },
                bp : {
                    unit: "mmHg",
                    sys: '',
                    dia: ''
                },
                heartRate : {
                    unit: "BPM",
                    value: ''
                },
                bloodOxygen : {
                    unit: "percentage",
                    value: ''
                }
            }
        }
    },
    actions,
}