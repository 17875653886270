<template>
  <div>
    <v-app-bar
      app
      elevation="0"
      height="100%"
    >
      <v-col
        cols="12"
        lg="5"
        sm="11"
        md="6"
      >
        <router-link
          :to="isLoggedIn ? '/confirmed-appointment' : '/home'"
          class="top-login-left"
        >
          healthcare intelligence & Analytics (HIA)
        </router-link>
      </v-col>

      <!-- <v-col class="d-flex justify-end font-size-15" v-if="$route.path.includes('/home')">
                <router-link class="white--text" to="/registration"><v-icon>mdi-plus</v-icon> New Registration</router-link>
            </v-col> -->

      <v-col
        v-if="isLoggedIn"
        lg="7"
        sm="1"
        md="6"
      >
        <ul class="hide-menu-item-on-large-screen d-flex align-center justify-end">
          <li
            
            v-for="(menu, i) in navbarMenu"
            :key="`menu-items-${i}`"
          >
            <router-link
              class="orange--text"
              :to="menu.routePath"
            >
              {{ menu.name }}
            </router-link>
          </li>
          <li v-if="isLoggedIn">
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    outlined
                    color="orange"
                    dark
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-list>
                    <v-list-item>
                      <router-link
                        :to="{name: 'EditProfile'}"
                        class="font-size-15 profile-color"
                      >
                        <v-icon class="font-size-15">
                          mdi-card-account-details-outline
                        </v-icon> Profile
                      </router-link>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="cursor-pointer"
                        @click.prevent="onLogout"
                      >
                        <v-icon class="font-size-15">
                          mdi-logout
                        </v-icon> Logout
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </li>
        </ul>
        <v-app-bar-nav-icon
          class="show-menu-item-on-large-screen d-none"
          @click="drawer = !drawer"
        />
      </v-col>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$store.getters.authData"
      v-model="drawer"
      app
      temporary
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="image" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ $store.getters.authData.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="item in navbarMenuDrawer"
          :key="item.name"
          link
        >
          <v-list-item-content>
            <v-list-item-title>
              <router-link
                class="black--text"
                :to="item.routePath"
              >
                {{ item.name }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            color="error"
            @click.prevent="onLogout"
          >
            <v-icon>mdi-logout</v-icon> Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
    name: "Header",
    data: () => ({
        errorMessages: '',
        isLoggedIn: false,
        drawer: false,
        navbarMenu: [
            {name: 'Confirmed Appointments', routePath: '/confirmed-appointment'},
            {name: 'New Appointments', routePath: '/new-appointment'},
            // {name: 'My Patients', routePath: '/my-patients'},
        ],
        navbarMenuDrawer: [
            {name: 'Confirmed Appointments', routePath: '/confirmed-appointment'},
            {name: 'New Appointments', routePath: '/new-appointment'},
            // {name: 'My Patients', routePath: '/my-patients'},
            {name: 'My Profile', routePath: '/edit-profile'},
        ],
        image: ''
    }),
    created() {
        this.isLoggedIn = !!this.$store.getters["authStore/authData"];
    },
    mounted() {
        window.vueEvent.$on('profile_image', (image) => {
            this.image = image;
        });
    },
    methods: {
        async onLogout() {
            await this.$store.dispatch('authStore/logout');
            window.location = '/home'
        }
    }
}
</script>

<style scoped lang="scss">
ul {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        padding: 0 10px;

        a {
            font-size: 1rem;
        }

        &:last-child {
            padding: 0;
        }
    }
}
.top-login-left {
    cursor: pointer;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: orange;
    text-decoration: none;
}
@media (max-width: 768px) {
    .hide-menu-item-on-large-screen {
        display: none !important;
    }
    .show-menu-item-on-large-screen{
        display: block !important;
    }
}

.profile-color {
    color: #2c2c2c !important;
}

.transparent{
       background:rgba(255,255,255,0.5);
   }

</style>