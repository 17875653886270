import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

export default new Vuetify( {
    theme: {
      primary: colors.orange,
      secondary: '#fdfdfd',
      accent: colors.shades.white,
      error: colors.red.accent3
    }
  });
