<template>
  <v-app :class="{'login-bg': $route.path.indexOf('/home') >= 0}">
    <Header :key="headerKey" />
    <v-main>
      <v-container 
        v-if="$store.getters['authStore/authData'] && !routeNames.includes($route.name)" 
        class="left-aligned-container"
      >
        <v-row>
          <v-col
            v-if="showLeft"
            cols="12"
            md="5"
          >
            <DoctorProfile />
          </v-col>
          <v-col 
            cols="12" 
            md="7"
          >
            <router-view />
          </v-col> 
        </v-row>
      </v-container>
      <template v-else>
        <div :class="{'login-page': $route.path.indexOf('/home') >= 0}">
          <router-view />
        </div>
      </template>
      <v-snackbar
        v-model="snackbar.action"
        :timeout="snackbar.timeout"
        bottom
        right
        :color="snackbar.color"
        class="font-weight-bold"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/layout/Header";
import '@/assets/scss/common.scss';
import DoctorProfile from "@/components/DoctorProfile";
import {generateId} from "@/helpers/utility";

export default {
    name: 'App',
    components: {
        Header,
        DoctorProfile
    },
    data() {
        return {
            headerKey: '',
            routeNames: ['PatientCaseHistory'],
            showLeft: true,
            snackbar: {
                action: false,
                text: '',
                timeout: 1250,
                color: null
            }
        }
    },
    mounted() {
        window.vueEvent.$on('reloadHeader', () => {
            this.headerKey = generateId();
        });

        window.vueEvent.$on('toggleProfile', (value) => {
            this.showLeft = value;
        });

        window.vueEvent.$on('notification', (options) => {
            this.snackbar.action = options.action;
            this.snackbar.text = options.text;
            this.snackbar.color = options.color;
        });
    }
};
</script>
<style>
 a {
     text-decoration: none !important;
 }
 .login-page {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     height: 100%;
 }
 .left-aligned-container {
  margin: 10px;
  margin-top: 1px ;
 }
</style>