import {postData} from "@/helpers/apiRuquests";
import {removeAllCookies} from "@/helpers/utility";

export default {
    async login(context, params) {
        let response = await postData('/auth', params);
        context.commit('SET_AUTH_DATA', response.data);
        return response;
    },
    async logout(context) {
        context.commit('SET_AUTH_DATA', null);
        removeAllCookies();
    }
};