import {empty} from "@/helpers/utility";

export default {
    SET_ALL_TREATMENTS(state, payload) {
        state.allTreatments = payload;
    },
    SET_TREATMENTS_FOR_THE_DISEASE(state, payload) {
        state.treatmentsForDisease = payload;
    },
    SET_SELECTED_TREATMENTS(state, payload) {
        state.selectedTreatments = payload;
    },
    SET_ONE_SELECTED_TREATMENT(state, payload) {
        let data = state.selectedTreatments.find(item => item.treatmentId === payload.treatmentId);
        if (empty(data)) {
            state.selectedTreatments.push(payload);
        }
    },
    REMOVE_ONE_SELECTED_TREATMENT(state, id) {
        state.selectedTreatments = state.selectedTreatments.filter(function (item) {
            return item.treatmentId !== id;
        });
    },
    SET_ONE_TREATMENT(state, payload) {
        state.treatmentsForDisease.push(payload);
    },
    REMOVE_ONE_TREATMENT(state, key) {
        state.treatmentsForDisease.splice(key, 1);
    }
}