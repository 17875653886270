import actions from "@/store/modules/auth/actions";
import mutations from "@/store/modules/auth/mutations";
import getters from "@/store/modules/auth/getters";
import VueCookies from "vue-cookies";

export default {
    namespaced: true,
    state() {
        return {
            authInfo: VueCookies.isKey('auth') ? VueCookies.get('auth') : null,
        }
    },
    actions,
    mutations,
    getters
}