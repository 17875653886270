import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from "./router";
import store from './store';
import moment from 'moment';
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
// global event register
window.vueEvent = new Vue();
Vue.use(VueCookies, {expires: '24h', path: '/', domain: '', secure: '', sameSite: 'Lax'});

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app');
