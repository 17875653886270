import {empty} from "@/helpers/utility";

export default {
    SET_APPOINTMENT_LIST(state, data) {
        state.list.now = data?.todayAppointments ?? [];
        state.list.future = data?.upcomingAppointments ?? [];
    },
    SET_PHYSICAL_INVESTIGATION(state, payload) {
        let data = JSON.parse(payload);
        if (!empty(data)) {
            if (!empty(data.height.ft) && !empty(data.height.in)) {
                state.physicalInvestigation.height = data.height;
            }
            if (!empty(data.bp.dia) && !empty(data.bp.sys)) {
                state.physicalInvestigation.bp = data.bp;
            }

            if (!empty(data.heartRate.value)) {
                state.physicalInvestigation.heartRate = data.heartRate;
            }

            if (!empty(data.bloodOxygen.value)) {
                state.physicalInvestigation.bloodOxygen = data.bloodOxygen;
            }

            if (!empty(data.temperature.value)) {
                state.physicalInvestigation.temperature = data.temperature;
            }

            if (!empty(data.weight.value)) {
                state.physicalInvestigation.weight = data.weight;
            }
        }
    }
}