<template>
  <div>
    <v-card
      v-if="personalProfileInformation"
      max-width="374"
      class="d-sm-none d-md-block"
    >
      <v-img
        height="250"
        :src="personalProfileInformation.image ? personalProfileInformation.image : user"
        contain
      />

      <v-card-title>{{ personalProfileInformation.name }}</v-card-title>

      <v-card-text>
        <div class="d-flex align-center mb-1">
          <v-icon class="me-1">
            mdi-email
          </v-icon>
          {{ personalProfileInformation.email }}
        </div>
        <div class="d-flex align-center">
          <v-icon class="me-1">
            mdi-hospital-marker
          </v-icon>
          {{ personalProfileInformation.hospital }}
        </div>
      </v-card-text>

      <v-divider class="mx-4" />

      <v-card-title class="text-subtitle-2 pt-1 pb-1">
        BM&DC No:
      </v-card-title>
      <v-card-text>{{ personalProfileInformation.bmdcNo }}</v-card-text>

      <template v-if="personalProfileInformation.designation">
        <v-card-title class="text-subtitle-2 pt-1 pb-1">
          Degrees:
        </v-card-title>
        <v-card-text>
          <v-chip-group
            active-class="deep-purple accent-4 white--text"
            column
          >
            <v-chip
              v-for="(item, index) in personalProfileInformation.designation.split(',')"
              :key="index"
              class="caption"
              style="height: 25px;"
            >
              {{ item }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </template>


      <template v-if="personalProfileInformation.specialization">
        <v-card-title class="text-subtitle-2 pt-1 pb-1">
          Specialization/ Area of Interest:
        </v-card-title>
        <v-card-text>
          <v-chip-group
            active-class="deep-purple accent-4 white--text"
            column
          >
            <v-chip
              v-for="(item, index) in personalProfileInformation.specialization.split(',')"
              :key="index"
              class="caption"
              style="height: 25px;"
            >
              {{ item }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </template>
    </v-card>


    <v-card
      v-if="personalProfileInformation"
      class="d-none d-sm-flex d-md-none justify-space-between"
    >
      <div class="profile-left-section">
        <img
          class="profile-image"
          :src="personalProfileInformation.image ? personalProfileInformation.image : user"
          :alt="personalProfileInformation.name"
        >
      </div>

      <div class="profile-right-section">
        <div>
          <v-card-title class="justify-end">
            {{ personalProfileInformation.name }}
          </v-card-title>

          <v-card-text>
            <div class="d-flex justify-end align-center mb-1">
              <v-icon class="order-1 ml-1">
                mdi-email
              </v-icon>
              {{ personalProfileInformation.email }}
            </div>
            <div class="d-flex justify-end align-center">
              <v-icon class="order-1 ml-1">
                mdi-hospital-marker
              </v-icon>
              {{ personalProfileInformation.hospital }}
            </div>
          </v-card-text>
        </div>

        <v-card-title class="justify-end text-subtitle-2 pt-1 pb-1">
          BM&DC No:
        </v-card-title>
        <v-card-text class="d-flex justify-end">
          {{ personalProfileInformation.bmdcNo }}
        </v-card-text>

        <template v-if="personalProfileInformation.designation">
          <v-card-title class="justify-end text-subtitle-2 pt-1 pb-1">
            Degrees:
          </v-card-title>
          <v-card-text class="pl-0 pr-0">
            <v-chip-group
              active-class="deep-purple accent-4 white--text"
              column
              class="d-flex justify-end"
            >
              <v-chip
                v-for="(item, index) in personalProfileInformation.designation.split(',')"
                :key="index"
                class="caption"
                style="height: 25px;"
              >
                {{ item }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </template>


        <template v-if="personalProfileInformation.specialization">
          <v-card-title class="justify-end text-subtitle-2 pt-1 pb-1">
            Specialization/ Area of Interest:
          </v-card-title>
          <v-card-text class="pl-0 pr-0">
            <v-chip-group
              active-class="deep-purple accent-4 white--text"
              column
              class="justify-end"
            >
              <v-chip
                v-for="(item, index) in personalProfileInformation.specialization.split(',')"
                :key="index"
                class="caption"
                style="height: 25px;"
              >
                {{ item }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </template>
      </div>
    </v-card>
  </div>
</template>

<script>
import user from "../assets/images/user.png";

export default {
    name: "DoctorProfile",
    data: () => ({
        user: user,
        personalProfileInformation: null,
        cardLoader: true,
    }),
    created() {
        this.getUserProfileInformation();
    },
    methods: {
        async getUserProfileInformation() {
            try {
                let response = await this.$store.dispatch('profileStore/getProfile');
                this.personalProfileInformation = response.data;
                let image = this.personalProfileInformation.image ? this.personalProfileInformation.image : user;
                window.vueEvent.$emit('profile_image', image);
            } catch (exception) {
                console.log(exception)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: auto) and (min-width: 600px) {
    .profile-image {
        width: 200px;
    }
    .profile-left-section {
        display: flex;
    }
    .v-slide-group__content {
        justify-content: end !important;
    }
}
</style>