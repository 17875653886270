import {fetchData, postData} from "@/helpers/apiRuquests";

export default {
    async symptoms(context) {
        let response = await fetchData('/symptoms');
        context.commit('SET_ALL_SYMPTOMS', response.data);
        return response;
    },
    async store(context, params) {
        let response = await postData('/symptoms', params);
        let data = response.data;
        data.symptom_bn = null;
        data.status = 1;
        context.commit('SET_SELECTED_SYMPTOM_IDS', data.id);
        context.commit('SET_ONE_SYMPTOM', data);
        return data;
    },
    async diseasePrediction(context, params) {
        let response = await postData('/prediction', params);
        context.commit('SET_MATCHED_DISEASE', response.data);
        return response;
    }
}