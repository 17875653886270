import {empty} from "@/helpers/utility";
import VueCookies from "vue-cookies";

export default {
    SET_AUTH_DATA: (state, payload) => {
        if (empty(payload)) {
            state.authInfo = null;
        } else {
            state.authInfo = payload;
            VueCookies.set('auth', payload);
        }
    }
}