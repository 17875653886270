import moment from "moment/moment";
import HeaderImage from '@/assets/images/header.png';
import FooterImage from '@/assets/images/footer.png';
import VueCookies from "vue-cookies";

function empty(data) {
    if (!data) {
        return true;
    }
    let type = typeof data;
    switch (type.toLowerCase()) {
        case 'string':
            return parseInt(data) === 0;
        case 'object':
            return Object.keys(data).length === 0;
    }
}

function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
}

function generateId(len) {
    let arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
}

function fireNotification(options) {
    window.vueEvent.$emit('notification', options);
}
function formatDate (date) {
    if (!date) return null;

    let year, month, day = '';

    let nowDate = new Date();

    year = nowDate.getFullYear();
    month = nowDate.getMonth();
    day = nowDate.getDate();

    let splited = date.split('-');

    if (splited.length >= 1) {
        day = splited[0];
    }

    if (splited.length >= 2) {
        month = splited[1];
    }

    if (splited.length >= 3) {
        year = splited[2];
    }

    return `${year}-${month}-${day}`;
}

function displayFormat(date) {
    if (!date) return null;

    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
}

function arrDiff(first, second) {
    return first.filter(id => !second.includes(id));
}

function arrayUnique(value, index, array) {
    return array.indexOf(value) === index;
}

function printOrSavePdf(html) {
    const newWindow = window.open('', '', 'height=700, width=1500');
    newWindow.document.write(html);
    newWindow.document.close();
    newWindow.moveTo(0, 0);
    newWindow.resizeTo(screen.width, screen.height);
    // a.print();
    setTimeout(function() {
        newWindow.print();
        newWindow.close();
    }, 250);
}

function slugify(string, separator = "-") {
    return string
        .toString()
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, separator)
        .replace(/^-+|-+$/g, '');
}


function getPrescriptionHtml(data) {
    let tableRows = '';
    if (data?.medicines.length) {
        for (let index in data.medicines) {
            let item = data.medicines[index];
            tableRows += `
                        <tr>
                            <td style="padding-bottom: 10px;">${++index}. (${item?.application?.intake?.toUpperCase()}) ${item?.name}</td>
                        </tr>
                        <tr>
                            <td style="padding-bottom: 10px;">
                                <span style="display: inline-block; padding: 0 15px;">${item?.dosage}</span>
                                <span style="display: inline-block; padding: 0 15px;">${item?.duration?.value} Days</span>
                                <span style="display: inline-block; padding: 0 15px;">${item?.application?.time}</span>
                            </td>
                        </tr>
                    `;
        }

        if (data.continueMedicine) {
            tableRows += `
            <tr>
                <td colspan="2">
                    <div class="section-gap"></div>
                    Continue Medicine for ${data.continueMedicine}
                </td>
            </tr>
            `;
        }
    }

    return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${slugify(`prescription ${moment().format('YYYY-MM-DD hhmmss')}`)}</title>
            <style>
                body { margin: 0; padding: 0; }
                .section-gap { height: 30px; }
            </style>
        </head>
        <body>
        <div class="header" style="position:relative; line-height: 0;">
            <img src="${HeaderImage}" style="width: 100%" alt="Heading image">
            <div style="position: absolute; left: 0; top: 70%; font-weight: 700; font-size: 20px;">Healthcare Intelligence & Analytics (HIA)</div>
        </div>
        <table style="width: 100%;">
            <tr>
                <td>
                    <div class="doctor-section">
                        <p style="font-size: 18px; font-weight: 700;">${data.doctor?.name}</p>
                        <p style="padding: 5px 0; margin: 0;">${data.doctor?.designation}, ${data.doctor?.hospital}</p>
                        <p style="padding-bottom: 5px; margin: 0;">${data.doctor?.specialization}</p>
                    </div>
                </td>
                <td>
                    <div class="patient-section">
                        <table style="text-transform: uppercase;">
                            <tr>
                                <td style="font-weight: 700;">Name</td>
                                <td style="padding: 0 15px; font-weight: 700;">:</td>
                                <td>${data.patient?.name}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 700;">Phone</td>
                                <td style="padding: 0 15px; font-weight: 700;">:</td>
                                <td>${data.patient?.phone_no}</td>
                            </tr>
                            <!--<tr>
                                <td style="font-weight: 700;">Address</td>
                                <td style="padding: 0 15px; font-weight: 700;">:</td>
                                <td>Purbo nurerchala bazar, vatart, gulshan 2 , dhaka - 1212</td>
                            </tr>-->
                            <tr>
                                <td style="font-weight: 700;">Sex</td>
                                <td style="padding: 0 15px; font-weight: 700;">:</td>
                                <td>${data.patient?.gender}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 700;">Age</td>
                                <td style="padding: 0 15px; font-weight: 700;">:</td>
                                <td>${getAgeFromDOB(data.patient?.dob)} Y</td>
                            </tr>
                            <tr>
                                <td style="font-weight: 700;">Date</td>
                                <td style="padding: 0 15px; font-weight: 700;">:</td>
                                <td>${moment().format('YYYY-MM-DD')}</td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <div class="section-gap"></div>
                    <hr>
                </td>
            </tr>
            <tr>
                <td>
                    <div></div>
                </td>
                <td>
                    <div style="padding-left: 15px; border-left: 2px solid lightgray;">
                        <div style="font-size: 20px;">Rx.</div>
                        <div class="section-gap"></div>
                        <div style="padding-left: 1.5rem;">
                            <table style="font-weight: 700;">
                                ${tableRows}
                                <tr>
                                    <td colspan="2">
                                        Next Appointment after ${data.nextAppointment.value} ${data.nextAppointment.unit}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <div class="section-gap"></div>
        <div class="section-gap"></div>
        <div class="footer" style="line-height: 0; width: 100%;position: fixed; bottom: 0;">
            <img src="${FooterImage}" style="width: 100%" alt="Bottom image">
        </div>
        </body>
        </html>
    `;
}

const getAgeFromDOB = (dob) => {
    if (!dob) return 0;
    return Math.floor(moment(moment(new Date()).format('YYYY-MM-DD')).diff(moment(dob, "YYYY-MM-DD"), 'years'));
}

const removeAllCookies = () => {
    let keys = VueCookies.keys();
    if (!empty(keys)) {
        for (const key of keys) {
            VueCookies.remove(key);
        }
    }
}

export {
    empty,
    generateId,
    dec2hex,
    fireNotification,
    formatDate,
    displayFormat,
    arrDiff,
    arrayUnique,
    printOrSavePdf,
    getAgeFromDOB,
    getPrescriptionHtml,
    slugify,
    removeAllCookies
}